<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
      @selection-change="selectionChange"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width80 textAlignRight"
                >申请人姓名 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.username"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width80 textAlignRight"
                >手机号码 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.phone"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width80 textAlignRight"
                >所属公司 :
              </span>
              <div class="width300">
                <el-select v-model="searchData.factoryId" clearable>
                  <el-option
                    v-for="item in classifyList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 textAlignRight width80"
                >状态 :
              </span>
              <div class="width300">
                <el-select v-model="searchData.state" clearable>
                  <el-option
                    v-for="item in OMemberExamine"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <!-- <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width80 textAlignRight"
                >时间 :
              </span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :clearable="true"
                >
                </el-date-picker>
              </div>
            </div> -->
            <el-button
              size="medium"
              type="primary"
              @click="handleSearch"
              class="marginLeft10"
              style="margin-bottom: 10px"
              >搜索</el-button
            >
            <el-button
              size="medium"
              type="info"
              @click="handleReset"
              class="marginLeft10 marginRight10"
              style="margin-bottom: 10px"
              >重置</el-button
            >
          </div>
        </div>
      </template>
      <template slot="state" slot-scope="{ row }">
        {{ MMemberExamine[row.state] || "" }}
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button
          type="text"
          size="mini"
          @click="batch(row.id)"
          v-if="row.state == EMemberExamine.PendingReview"
          >审核</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      title="审核"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <avue-form
        ref="form"
        :defaults.sync="defaults"
        v-model="form"
        :option="formOption"
      >
        <!-- <template slot="details"> </template> -->

        <template slot="menuForm">
          <el-button @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            :loading="showLoadingForm"
            @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  memberExamineList,
  memberExamineStateUpdate,
} from "@/api/user/examine";
import { manageFactoryList } from "@/api/user/manage";
import dayjs from "dayjs";
import {
  EMemberExamine,
  MMemberExamine,
  OMemberExamine,
} from "@/enums/beCurrent/EBeCurrent";

export default {
  name: "index",
  data() {
    return {
      EMemberExamine: EMemberExamine,
      MMemberExamine: MMemberExamine,
      OMemberExamine: OMemberExamine,
      defaults: {},
      searchData: {
        title: "",
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      selectedRowKeys: [], //表格勾选中的数据
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        tip: false,
        column: [
          {
            label: "公司名称",
            prop: "companyName",
          },
          {
            label: "申请人姓名",
            prop: "username",
          },
          // {
          //   label: "岗位",
          //   prop: "post",
          // },
          {
            label: "手机号码",
            prop: "phone",
          },
          {
            label: "审核状态",
            prop: "state",
          },
          {
            label: "理由",
            prop: "reason",
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
        ],
      },

      dialogVisible: false,
      form: {
        state: 2,
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "100",
        row: true,
        column: [
          {
            label: "审核操作",
            prop: "state",
            type: "radio",
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择",
                trigger: "blur",
              },
            ],
            dicData: [
              {
                value: 2,
                label: "同意",
              },
              {
                value: 3,
                label: "拒绝",
              },
            ],
          },
          {
            label: "拒绝原因",
            prop: "reason",
            type: "textarea",
            span: 24,
            row: true,
            display: false,
            minRows: 3,
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      delId: "",
      delType: "", //1批量 2 单个
      classifyList: [], //所属公司分类
    };
  },
  watch: {
    "form.state"(val) {
      if (val == 3) {
        this.defaults.reason.display = true;
      } else {
        this.defaults.reason.display = false;
      }
    },
  },
  created() {
    this.getClassifyList();
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      delete searchData.createTime;
      memberExamineList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 添加
    handleCreate() {
      this.form = {
        details: "",
        id: "",
      };
      this.dialogVisible = true;
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置表单
    resetForm() {
      this.form = {
        state: 2,
      };
    },
    //编辑
    async handleEdit(row) {
      console.log(row);
      this.form.id = row.id;
      this.dialogVisible = true;
      this.form = { ...row, details: row.content };
    },
    //审核
    batch(id, type) {
      this.delId = id;
      this.dialogVisible = true;
    },

    //表格左侧选择的数据的
    selectionChange(list) {
      this.selectedRowKeys = list;
    },

    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          memberExamineStateUpdate({
            id: this.delId,
            ...form,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success("审核成功!");
              this.onLoad();
              this.dialogVisible = false;
            }
            this.showLoadingForm = false;
          });
        }
      });
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    //详情
    handleDetails(row) {
      this.$router.push({
        path: "/examine/create",
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
    //会员分类
    getClassifyList() {
      manageFactoryList({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.classifyList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped></style>
