import { get, post, put, Delete } from "../axios";

// 会员列表-账号审核-公司列表（用于会员列表筛选，新增和编辑）
export const manageFactoryList = (params) =>
  get("/web/member/manage/factory/list", params);

// 会员列表-列表
export const memberMemberList = (params) =>
  get("/web/member/manage/list", params);

// 会员列表-新增
export const memberManageAdd = (params) =>
  post("/web/member/manage/add", params);

// 会员列表-编辑
export const memberManageUpdate = (params) =>
  put("/web/member/manage/update", params);

// 会员列表-删除
export const memberManageDelete = (id) =>
  Delete("/web/member/manage/delete/" + id, "");

// 会员列表-详情
export const memberManageView = (id) =>
  get("/web/member/manage/view/" + id, "");

// 会员列表-密码修改
export const memberManagePassword = (params) =>
  put("/web/member/manage/password/update", params);

// 会员列表-状态修改
export const memberManageState = (params) =>
  put("/web/member/manage/state/update", params);
